<template>
  <v-navigation-drawer
    id="app-drawer"
    :value="drawer"
    :mobile-breakpoint="drawerBreakpoint"
    data-cy="navigation-drawer"
    app
    @input="onDrawerChange"
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-list-item class="e-nav-drawer--brand">
        <router-link
          :to="partnerViewPath"
          style="display: flex"
        >
          <v-list-item-avatar max-width="40px">
            <v-img
              :src="logo"
              contain
            />
          </v-list-item-avatar>
          <v-list-item-title class="title mb-1">
            <v-list-item-subtitle
              v-t="'drawer.headerSubtitle'"
              class="mb-1 subtitle"
            />
            <span v-t="'drawer.headerTitle'" />
          </v-list-item-title>
        </router-link>
      </v-list-item>

      <v-list-item
        v-if="isPartnerView"
        :to="{ path: partnerViewPath }"
        data-cy="partner-overview"
      >
        <v-list-item-action>
          <v-icon>{{ goBackIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title v-t="'drawer.routePartnerView'" />
      </v-list-item>

      <v-list-item
        v-for="link in links"
        :key="link.to"
        :to="{ name: link.to }"
        :data-cy="link.dataCy"
      >
        <v-list-item-action>
          <v-progress-circular
            v-if="loading[link.to]"
            :size="23"
            :width="3"
            indeterminate
          />
          <v-icon v-else>
            {{ link.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-title v-t="`drawer.${camelize(link.text)}`" />
      </v-list-item>
      <v-list-item>
        <template v-if="(isManagedUserTip || !isOverviewPage) && !isPaymentStatusPage">
          <v-alert
            prominent
            text
            color="info"
            border="top"
            style="width: 100%"
          >
            <template v-if="isManagedUserTip">
              <core-drawer-tip>
                <template slot="content">
                  <p
                    v-if="hasExternalPartner"
                    v-t="'tips.clientPartnerTip'"
                  />
                  <p
                    v-else
                    v-t="'tips.clientEasySoftTip'"
                  />
                  <core-element-manager-contacts class="mb-4" />
                  <material-request-quotation-modal-window />
                </template>
              </core-drawer-tip>
            </template>
            <template v-else-if="isProductDetailsPage">
              <core-drawer-tip>
                <template slot="content">
                  <p v-t="'tips.productDetails.p1'" />
                  <core-element-manager-contacts class="mb-4" />
                  <material-request-quotation-modal-window />
                </template>
              </core-drawer-tip>
            </template>
            <template v-else-if="isInvoicesPage">
              <core-drawer-tip>
                <template slot="content">
                  <p v-t="'tips.invoices.p1'" />
                  <p v-t="'tips.invoices.p2'" />
                </template>
              </core-drawer-tip>
            </template>
            <template v-else-if="isPaymentsPage">
              <core-drawer-tip>
                <template slot="content">
                  <p v-t="'tips.payment.p1'" />
                  <p v-t="'tips.payment.p2'" />
                </template>
              </core-drawer-tip>
            </template>
            <template v-else-if="isBillingPage">
              <core-drawer-tip>
                <template slot="content">
                  <p v-t="'tips.billing.p1'" />
                  <p v-t="'tips.billing.p2'" />
                </template>
              </core-drawer-tip>
            </template>
            <template v-else-if="isMembersPage">
              <core-drawer-tip>
                <template slot="content">
                  <b v-t="'tips.members.b1'" />
                  <ol>
                    <li v-t="'tips.members.li1'" />
                    <li v-t="'tips.members.li2'" />
                    <li v-t="'tips.members.li3'" />
                    <li v-t="'tips.members.li4'" />
                  </ol>
                  <p v-t="'tips.members.p1'" />
                </template>
              </core-drawer-tip>
            </template>

            <template v-else-if="isSupportPage">
              <core-drawer-tip>
                <template slot="content">
                  <p class="mb-0">
                    <span v-t="'tips.support.p1'" />
                    <a
                      v-t="'tips.support.link'"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.easyredmine.com/faq"
                    />
                  </p>
                  <small>{{ faqLink }}</small>
                </template>
              </core-drawer-tip>
            </template>
          </v-alert>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import {
  ROLE_ADMIN,
  GO_BACK_ICON,
  ACCESS_LEVELS_PERMISSIONS,
  ADMIN_ACCESS_LEVEL,
  PERMISSIONS_NAME
} from '../../constants/app'
import {
  ROUTE_OVERVIEW,
  ROUTE_INVOICES_AND_QUOTATIONS,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PAYMENT_CANCELED,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_PAYMENT_DETAILS,
  ROUTE_BILLING_DETAILS,
  ROUTE_TEAM_MEMBERS,
  ROUTE_SUPPORT_CENTER
} from '../../constants/routeNames'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    faqLink: '(https://www.easyredmine.com/faq)',
    goBackIcon: GO_BACK_ICON,
    logo: require('../../images/es-logo-mark.svg'),
    drawerBreakpoint: 1325,
    allLinks: [
      {
        to: ROUTE_OVERVIEW,
        icon: 'mdi-home',
        text: ROUTE_OVERVIEW,
        dataCy: 'overview'
      },
      {
        to: ROUTE_PRODUCT_DETAILS,
        icon: 'mdi-heart',
        text: ROUTE_PRODUCT_DETAILS,
        dataCy: 'product-details'
      },
      {
        to: ROUTE_INVOICES_AND_QUOTATIONS,
        icon: 'mdi-clipboard-outline',
        text: ROUTE_INVOICES_AND_QUOTATIONS,
        dataCy: 'invoices-quotations'
      },
      {
        to: ROUTE_PAYMENT_DETAILS,
        icon: 'mdi-credit-card',
        text: ROUTE_PAYMENT_DETAILS,
        dataCy: 'payment-details'
      },
      {
        to: ROUTE_BILLING_DETAILS,
        icon: 'mdi-email',
        text: ROUTE_BILLING_DETAILS,
        dataCy: 'billing-details'
      },
      {
        to: ROUTE_TEAM_MEMBERS,
        icon: 'mdi-account-multiple',
        text: ROUTE_TEAM_MEMBERS,
        dataCy: 'team-members'
      },
      {
        to: ROUTE_SUPPORT_CENTER,
        icon: 'mdi-lifebuoy',
        text: ROUTE_SUPPORT_CENTER,
        dataCy: 'support-center'
      }
    ]
  }),
  computed: {
    ...mapState('layout', ['drawer', 'loading']),
    ...mapGetters('client', ['userManager', 'hasExternalPartner']),
    links () {
      const clientPermissions = JSON.parse(localStorage.getItem(PERMISSIONS_NAME))
      if (clientPermissions.includes(ADMIN_ACCESS_LEVEL)) return this.allLinks

      let availableLinks = [ROUTE_SUPPORT_CENTER, ROUTE_OVERVIEW]
      for (const permission of clientPermissions) {
        availableLinks = [...availableLinks, ...ACCESS_LEVELS_PERMISSIONS[permission]]
      }
      return this.allLinks.filter(link => availableLinks.includes(link.to))
    },
    isPartnerView () {
      return localStorage.getItem('role') === ROLE_ADMIN
    },
    isManagedUserTip () {
      if (!this.userManager) {
        return false
      }

      const managedUserTipRoutes = [
        ROUTE_PRODUCT_DETAILS,
        ROUTE_OVERVIEW,
        ROUTE_INVOICES_AND_QUOTATIONS,
        ROUTE_PAYMENT_DETAILS
      ]

      return managedUserTipRoutes.some(route => route === this.$route.name)
    },
    partnerViewPath () {
      return `/${this.$route.params.locale}/my/overview`
    },
    isProductDetailsPage () {
      return this.$route.name.match(ROUTE_PRODUCT_DETAILS)
    },
    isOverviewPage () {
      return this.$route.name.match(ROUTE_OVERVIEW)
    },
    isInvoicesPage () {
      return this.$route.name.match(ROUTE_INVOICES_AND_QUOTATIONS)
    },
    isPaymentsPage () {
      return this.$route.name.match(ROUTE_PAYMENT_DETAILS)
    },
    isBillingPage () {
      return this.$route.name.match(ROUTE_BILLING_DETAILS)
    },
    isMembersPage () {
      return this.$route.name.match(ROUTE_TEAM_MEMBERS)
    },
    isSupportPage () {
      return this.$route.name.match(ROUTE_SUPPORT_CENTER)
    },
    isPaymentStatusPage () {
      return this.$route.name.match(ROUTE_PAYMENT_CANCELED) || this.$route.name.match(ROUTE_PAYMENT_SUCCESS)
    }
  },
  created () {
    if (this.innerWidth <= this.drawerBreakpoint) {
      this.setDrawer(false)
    }
  },
  methods: {
    ...mapMutations('layout', ['setDrawer', 'setLoading']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    onDrawerChange (val) {
      this.setDrawer(val)
    }
  }
}
</script>

<style scoped>
#app-drawer >>> .v-alert__content {
  width: 100%;
}
</style>
